import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import RightArrowWhite from "../../assets/Icons/accordian_white.svg";
import RightArrow from "../../assets/Icons/go.svg";
import HoverCardWithBtnIcon2 from "../../assets/Icons/Group_1.svg";
import HoverCardWithBtnIcon3 from "../../assets/Icons/Group_2.svg";
import HoverCardWithBtnIcon5 from "../../assets/Icons/Group_3.svg";
import HoverCardWithBtnIcon4 from "../../assets/Icons/Group_3042.svg";
import Clm2Img from "../../assets/Icons/mail.svg";
import HoverCardWithBtnIcon1 from "../../assets/Icons/outrun.svg";
import Clm1Img from "../../assets/Icons/phone.svg";
import BackArrowIcon from "../../assets/Images/accordian.svg";
import contactImage from "../../assets/Images/caseStudy/contact_us.png";
import contactImageHover from "../../assets/Images/caseStudy/contact_us_hover.png";
import BannerImage from "../../assets/Images/caseStudy/takealook.png";
import HoverImage from "../../assets/Images/caseStudy/takealook_hover.png";
import clutchLogo from "../../assets/Images/WhatWeDo/clutch-co-vector-logo.svg";
import quote from "../../assets/Images/WhatWeDo/quote.svg";
import Button from "../../components/Button";
import Layout from "../../components/layouts/layout";
import DataService from "../../services/api/data_services";
import { BASE_SITE_URL } from "../../shared/constants";
import ContactUsRightGrid from "../../templates/ContactUsRightGrid";
import ImageBanner from "../../templates/ImageBanner";
import LeftGrid from "../../templates/LeftGrid";
import Miniview from "../../templates/MiniView";
import TwoColumnContactInfo from "../../templates/TwoColumnContactInfo";
import ViewPdfRightGrid from "../../templates/ViewPdfRightGrid";

const ClientStory = ({ pageContext: { clientstory } }) => {
  const [page, setPage] = useState(1);
  const [clientStoryData, setClientStoryData] = useState([]);
  const [clientStoryRawData, setClientStoryRawData] = useState([]);
  const [clientStoryContent, setClientStoryContent] = useState([]);
  const queryString =
    typeof window !== "undefined" ? window.location.href.split("/")[4] : "";
  const [isHover, setIsHover] = useState(false);
  const toggleHover = () => {
    setIsHover(!isHover);
  };

  useEffect(() => {
    DataService.getData(
      `/admin/api/post?limit=3&page=${page}&page_id=1&type=Client Story&component=mini_view`
    ).then((response) => {
      setClientStoryData([...clientStoryData, ...response.data]);
      setClientStoryRawData(response);
    });
  }, [page]);

  useEffect(() => {
    DataService.getData(`/admin/api/post-details?slug=${queryString}`)
      .then((response) => {
        const clientStoryContent = response.data;
        setClientStoryContent(clientStoryContent);
      })
      .catch((error) => {
        navigate(-1);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString]);
  /**
   * Image Banner
   */
  const ImageBannerData = {
    bnrImg: clientStoryContent.image,
    specificStyle: "yes",
    component: "Who",
    bannerImageAlt: clientStoryContent.bannerImageAlt,
    bnrImgTitle: clientStoryContent.banner_image_title,
  };
  /**
   * TwoColumnContactInfo
   */

  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };
  /**
   * Six Hover Card
   */

  const SixHoverCardData = {
    HoverCardWithBtnIcon1: HoverCardWithBtnIcon1,
    HoverCardWithBtnIcon2: HoverCardWithBtnIcon2,
    HoverCardWithBtnIcon3: HoverCardWithBtnIcon3,
    HoverCardWithBtnIcon4: HoverCardWithBtnIcon4,
    HoverCardWithBtnIcon5: HoverCardWithBtnIcon5,
    HoverCardWithBtnContent1: clientStoryContent.secondary_description,
    keyPointsBig1: clientStoryContent.key_points_big,
    keyPointsBig2: clientStoryContent.key_points_big1,
    keyPointsBig3: clientStoryContent.key_points_big2,
    keyPoints1: clientStoryContent.key_points,
    keyPoints2: clientStoryContent.key_points1,
    keyPoints3: clientStoryContent.key_points2,
    route: "/contact-us",
  };

  const VideobannerData = {
    BannerImage: BannerImage,
    HoverImage: HoverImage,
    route: clientStoryContent.pdf_file,
    ImageBannerTitle: "See the full Story",
  };

  const VideobannerDataNoPDF = {
    BannerImage: contactImage,
    HoverImage: contactImageHover,
    route: "/contact-us",
    ImageBannerTitle: "See the full Story",
  };

  const miniviewData2 = {
    height: "490",
    service: "machineLearning",
    title: "Let's Talk",
    subTitle: "We'd love to help with your product.",
    variant: "contained",
    label: "GET IN TOUCH",
    customClass: "miniCrdBgImg-main-border",
    route: "/contact-us",
  };

  const quotedMiniCardData = {
    title: "Mobile App Dev for Automative Sales App",
    heading:
      "We can’t speak highly enough of Digiryte’s team because they bent over backward to help us launch our product.",
    content: "Founder, Bumper UK",
    footerContent: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>Authentic client review from</div>
        <div style={{ paddingLeft: "5px" }}>
          <img src={clutchLogo} alt="Clutch" height="50px" />
        </div>
      </div>
    ),
  };
  return (
    <Layout
      seo={{
        title: clientStoryContent?.og_title,
        ogtitle: clientstory?.og_title,
        description: clientstory
          ? clientstory?.og_description
          : clientStoryContent?.og_description,
        ogurl: `${BASE_SITE_URL}/client-story/${clientstory?.slug}/`,
        ogimage: clientstory?.client_og_image,
      }}
    >
      <ImageBanner imageBannerData={ImageBannerData} />
      <div className="mt-50 body-container">
        <Button
          icon={
            <img
              className="effect-btn-svgicon"
              src={BackArrowIcon}
              alt="click"
            />
          }
          customClassName="effect-btn"
          className="mui-button-font"
          tip="Who we work with"
          routepath="/who-we-work-with"
        />
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "600",
            marginTop: "-42px",
            marginLeft: "40px",
          }}
        >
          Who we work with
        </Typography>
      </div>

      <div id="html-content"></div>
      <div className="mt-30 body-container">
        {clientStoryContent && clientStoryContent.primary_description_1 && (
          <div
            className="dynamic-description-one"
            dangerouslySetInnerHTML={{
              __html: `${clientStoryContent.primary_description_1}`,
            }}
          />
        )}
        <br />
        {clientStoryContent && clientStoryContent.primary_description_2 && (
          <div
            className="dynamic-description-one"
            dangerouslySetInnerHTML={{
              __html: `${clientStoryContent.primary_description_2}`,
            }}
          />
        )}
        <br />
        {clientStoryContent && clientStoryContent.primary_description_3 && (
          <div
            className="dynamic-description-one"
            dangerouslySetInnerHTML={{
              __html: `${clientStoryContent.primary_description_3}`,
            }}
          />
        )}
        {clientStoryContent.testimonial &&
          clientStoryContent.testimonial.map((testimonial) => (
            <div
              style={{ position: "relative" }}
              className="mt-50 body-container"
            >
              <div className="dynamic-quote">
                <img alt="quote" src={quote} />
              </div>
              <br />
              <Typography className="testimonial-content">
                {testimonial.content}
              </Typography>
              <Typography className="testimonial-client mt-20">
                - {testimonial.client_name}
              </Typography>
            </div>
          ))}
      </div>
      <div className="mt-50 body-container">
        <img
          style={{ width: "100%" }}
          src={clientStoryContent.magic_box_image}
          alt={clientStoryContent.imageAltTxt}
        />
      </div>
      {clientStoryContent.video_url !== null && (
        <div className="mt-50 body-container">
          {/* <iframe
            src={clientStoryContent.video_url}
            frameborder="0"
            style={{ borderRadius: "10px" }}
            allow="autoplay; encrypted-media"
            allowfullscreen
            title={clientStoryContent.video_description}
            width="100%"
            height="495"
          ></iframe> */}
          <video autoPlay playsInline loop muted id="myVideo">
            <source src={clientStoryContent.video_url} type="video/mp4" />
          </video>
        </div>
      )}
      <div
        style={{ textAlign: "center", fontWeight: "600" }}
        className="article-main mt-30 body-container"
      >
        {clientStoryContent.video_description}
      </div>
      <div id="gated" className="mt-50 body-container">
        <div className="title-header">{clientStoryContent.secondary_title}</div>
      </div>
      <div className="mt-50 body-container">
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} sm={12} md={6} className="card crd-spc">
            <LeftGrid SixHoverCardData={SixHoverCardData} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} className="card crd-spc">
            {clientStoryContent.pdf_file !== "NULL" &&
            clientStoryContent.show_contact_form === 1 ? (
              <ContactUsRightGrid
                bannerTitle={clientStoryContent.banner_image_title}
                pdfFile={clientStoryContent.pdf_file}
                articleid={clientStoryContent.id}
              />
            ) : (
              ""
            )}
            {clientStoryContent.pdf_file === "NULL" &&
            clientStoryContent.show_contact_form === 2 ? (
              <ViewPdfRightGrid VideobannerData={VideobannerDataNoPDF} />
            ) : (
              ""
            )}
            {clientStoryContent.pdf_file !== "NULL" &&
            clientStoryContent.show_contact_form === 2 ? (
              <ViewPdfRightGrid VideobannerData={VideobannerData} />
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </div>
      {clientStoryData && clientStoryData.length > 0 && (
        <React.Fragment>
          <div className="body-container mt-70">
            <h2 className="title-header ttc">
              <span className="red-line3letter">Fea</span>tured Client stories
            </h2>
          </div>
          <div className="mt-50">
            <Miniview
              miniviewData={clientStoryData}
              miniviewData2={miniviewData2}
              total={clientStoryData.length}
              quotedMiniCardData={quotedMiniCardData}
            />
          </div>
        </React.Fragment>
      )}
      <div style={{ width: "100%", textAlign: "center" }}>
        <div
          onMouseEnter={toggleHover}
          onMouseLeave={toggleHover}
          className="mt-60"
          role="presentation"
          onClick={() => setPage(page + 1)}
          style={{ display: "inline-block" }}
        >
          {clientStoryRawData.has_more && (
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="Load More"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="Load More"
            />
          )}
        </div>
      </div>
      <div className="mt-70"></div>
      <TwoColumnContactInfo
        TwoColumnContactInfoData={TwoColumnContactInfoData}
      />
    </Layout>
  );
};

export default ClientStory;
